import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ZakekeEnvironment, ZakekeProvider } from 'zakeke-configurator-react';
import Selector from './selector';
import View from './view';


const Layout = styled.div`
    height: 100%;
    -webkit-tap-highlight-color: transparent;
    padding: 0;
    display: block;
    overflow: hidden;
    @media (max-width: 768px) {
        flex-flow: column-reverse;
        display: flex;
        padding: 0;
    }
`
const Viewer = styled.div`
    width: calc(100% - 300px);
    height: inherit;
    float: left;
    @media (max-width: 1100px) {
        width: calc(100% - 275px);
    }
    @media (max-width: 1000px) {
        width: calc(100% - 250px);
    }
    @media (max-width: 768px) {
        margin-bottom: 70px;
        width: 100%;
        height: 130%;
    }
`

const zakekeEnvironment = new ZakekeEnvironment();

const App: FunctionComponent<{}> = () => {

    return <ZakekeProvider environment={zakekeEnvironment}>
                <Layout>
                    <Selector />
                    <Viewer>
                        <View />
                    </Viewer>
                </Layout>
            </ZakekeProvider>;
}

export default App; 