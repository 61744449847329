import React, { FunctionComponent, Fragment } from 'react';
import styled from 'styled-components';
import { useZakeke, ZakekeViewer } from 'zakeke-configurator-react';
import { ZoomInIcon, ZoomOutIcon } from './list';
import { ReactComponent as SearchPlusSolid } from '../assets/icons/search-plus-solid.svg';
import { ReactComponent as SearchMinusSolid } from '../assets/icons/search-minus-solid.svg';

const ViewerLayout = styled.div`
    diplay: block;
    height: inherit;
    font-family: open sans,public sans,sans-serif;
`;

const AddToCart = styled.div`
    position: relative;
    display: flex;
    top:-10%;
    background-color: #c33d31;
    color: white;
    width: fit-content;
    float: right;
    margin-right: 5px;
    padding: 0 25px 0 25px;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    border: white;
    height: 50px;
    font-weight: bold;
    font-size: 15px;

    @media (max-width: 768px) {
        height: 40px;
        font-size: 15px;
        top: -20%;
    }

    @media (max-width: 500px) {
        height: 30px;
        font-size: 11px;
        padding: 0 15px 0 15px;

    }
    @media (max-height: 740px) {
        top: -15%;
    }
    @media (max-height: 540px) {
        top: -20%;
    }
`;

const ProductNameLayout = styled.div`
    position: relative;
    top:-98%;
    padding-right: 20px;
    font-size: 25px;
    color: #231f20;
    text-align: right;
    font-weight: bolder;

    @media (max-width: 768px) {
        font-size: 15px;
    }
`;

const View: FunctionComponent<{}> = () => {

    const { zoomIn, zoomOut, isSceneLoading, isAddToCartLoading, addToCart, productName, saveComposition} = useZakeke();

    return (
        <Fragment>
            <ViewerLayout>
                <ZakekeViewer />
                {productName && !isSceneLoading &&
                    <ProductNameLayout>
                        {productName.toUpperCase()}
                    </ProductNameLayout>
                }
                {!isSceneLoading &&
                    <div style={{ position:"relative", float:"right", marginRight:"10px", top: "-55%" }}>
                        <ZoomInIcon hoverable onClick={zoomIn} ><SearchPlusSolid /></ZoomInIcon>
                        <ZoomOutIcon hoverable onClick={zoomOut} ><SearchMinusSolid /></ZoomOutIcon>
                        
                    </div>
                }
                {!isSceneLoading &&
                    <Fragment>
                        <AddToCart onClick={() => addToCart({})}>
                            {isAddToCartLoading ? <p>Adding to cart...</p> : <p>ADD TO CART</p>}
                        </AddToCart>
                        <AddToCart onClick={saveComposition}>
                            {isAddToCartLoading ? <p>Saving...</p> : <p>SAVE</p>}
                        </AddToCart>
                    </Fragment>
                }
            </ViewerLayout>
        </Fragment>
    )
}

export default View;