import React, { FunctionComponent, Fragment } from 'react';
import { useZakeke } from 'zakeke-configurator-react';
import { useState } from 'react';
import { useEffect } from 'react';
import { List, ListItem, RadioItem, RadioButton, RadioButtonLabel } from './list';
import styled from 'styled-components';
// import { getDefinitiveGroups } from 'Helpers';


const Container = styled.div`
    width: 25%;
    float: left;
    min-width: 250px;
    max-width: 300px;
    height: 100%;
    overflow-y: scroll;
    @media (max-width: 768px) {
        width: 100%;
        max-width: 100%;
        margin-top:20px;
    }
`;

const LoaderLayout = styled.div`
position: relative;
    display: flex;
    height: 100vh; 
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
        position: absolute;
        top: 0;
        botton: 0;
        left: 0;
        right: 0;
    }
`;

const Selector: FunctionComponent<{}> = () => {

    const { isSceneLoading, groups, selectOption, loadComposition, addToCart, currentTemplate, templates, items, setTemplate,
        getMeshIDbyName, hideMeshAndSaveState, restoreMeshVisibility, setCamera
    } = useZakeke();

    // Keep saved the ID and not the refereces, they will change on each update
    const [selectedGroupId, selectGroup] = useState<number | null>(null);
    const [prevSelectedGroupId, setPrevSelectedGroupId] = useState<number | null>(null);
    const [selectedStepId, selectStep] = useState<number | null>(null);
    const [selectedAttributeId, selectAttribute] = useState<number | null>(null);

    // const actualGroups = getDefinitiveGroups(groups, shouldCustomizerGroupBeVisible) ?? [];
    // const selectedGroup = selectedGroupId ? actualGroups.find(group => group.id === selectedGroupId) : null;

    const selectedGroup = groups.find(group => group.id === selectedGroupId);
    const selectedStep = selectedGroup ? selectedGroup.steps.find(step => step.id === selectedStepId) : null;

    // Attributes can be in both groups and steps, so show the attributes of step or in a group based on selection
    const attributes = (selectedStep || selectedGroup)?.attributes ?? [];
    const selectedAttribute = attributes.find(attribute => attribute.id === selectedAttributeId);

    const [occhiali1Id, setOcchiali1Id] = useState<string | null>(null);
    const [occhiali2Id, setOcchiali2Id] = useState<string | null>(null);
    const [astuccioId, setAstuccioId] = useState<string | null>(null);

    // Open the first group and the first step when loaded
    useEffect(() => {
        if (!selectedGroup && groups.length > 0) {
            selectGroup(groups[0].id);

            if (groups[0].steps.length > 0)
                selectStep(groups[0].steps[0].id);

            if (templates.length > 0)
                setTemplate(templates[0].id)
        }
    }, [selectedGroup, groups]);

    // Select attribute first time
    useEffect(() => {
        if (!selectedAttribute && attributes.length > 0)
            selectAttribute(attributes[0].id);
    }, [selectedAttribute, attributes])


    useEffect(() => {

        if (isSceneLoading === false && groups.length > 0) {
            const occhiali1 = getMeshIDbyName('MODELLO_B');
            const occhiali2 = getMeshIDbyName('NAU_ALL');
            const astuccio = getMeshIDbyName('astucchio');

            setOcchiali1Id(occhiali1 ?? '');
            setOcchiali2Id(occhiali2 ?? '');
            setAstuccioId(astuccio ?? '');
        }
    }, [isSceneLoading]);

    useEffect(() => {
        if (!isSceneLoading && groups.length > 0) {
            const astuccioGroup = groups.find(group => group.name === 'ASTUCCIO');

            if (astuccioGroup && selectedGroupId == astuccioGroup.id) {
                restoreMeshVisibility(astuccioId!);

                setTimeout(() => {
                    hideMeshAndSaveState(occhiali1Id!);
                    hideMeshAndSaveState(occhiali2Id!);
                }, 2000);
            } else if (astuccioGroup && prevSelectedGroupId == astuccioGroup.id) {
                restoreMeshVisibility(occhiali1Id!);
                restoreMeshVisibility(occhiali2Id!);
                
                setTimeout(() => {
                    hideMeshAndSaveState(astuccioId!);
                }, 2000);
            }

            console.log({ second: astuccioGroup, selectedGroupId });
        }
    }, [selectedGroupId, isSceneLoading]);

    useEffect(() => {
        if (selectedGroup) {
            const camera = selectedGroup.cameraLocationId;
            if (camera)
                setCamera(camera);
        }
    }, [selectedGroupId]);
 
    if (isSceneLoading || !groups || groups.length === 0) {
        return <LoaderLayout>Loading scene...</LoaderLayout>;
    }

// const redirectToHome = () => {
//     parent.document.location.href = "https://caraseltrailers.com.au/";
// }
    // groups
    // -- attributes
    // -- -- options
    // -- steps
    // -- -- attributes
    // -- -- -- options
    return <Container>
        <a target={"_top"} href="https://caraseltrailers.com.au/" style={{ textDecoration:"inherit", color: "inherit" }}>
            <List>
                    <ListItem>
                            CHANGE MODEL
                    </ListItem>
            </List>
        </a>
        {selectedGroup && selectedGroup.steps.length > 0 && 
            <List>
                {selectedGroup.steps.map(step => {
                    return <ListItem key={step.id} onClick={() => selectStep(step.id)} selected={selectedStep === step}>Step: {step.name}</ListItem>;
                })}
            </List>
        }

        <List>
                {attributes && attributes.map(attribute => {
                    return ( attribute.enabled &&
                        <Fragment>
                            <ListItem isOption={false} key={attribute.id} onClick={() => selectAttribute(attribute.id)} selected={selectedAttribute === attribute}>{attribute.name}
                                {   selectedAttribute === attribute &&
                                    <List>
                                        {selectedAttribute && selectedAttribute.options.map(option => {

                                            return (
                                                <Fragment>
                                                    <RadioItem >
                                                        <RadioButton
                                                            type="radio"
                                                            label={option.name}
                                                            id={attribute.id + option.name}
                                                            name={attribute.id + option.name}
                                                            checked={option.selected}
                                                            onChange={() => selectOption(option.id)}>
                                                        </RadioButton>
                                                        <RadioButtonLabel />
                                                        <label htmlFor={attribute.id + option.name}>{option.name}</label>
                                                    </RadioItem>
                                                </Fragment>
                                            )
                                        })}
                                    </List>
                                }
                            </ListItem>
                        </Fragment>
                    )
                })}
        </List>
    </Container>
}

export default Selector;