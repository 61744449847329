import styled from "styled-components";

export const List = styled.ul`
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    font-family: open sans,public sans,sans-serif;
    flex-wrap:wrap;
    width: 100%;
`;

export const ListItem = styled.li<{ selected?: boolean, isOption?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    justify-content: left;
    padding: 20px 0px 20px 50px;
    border: 1px #DDD solid;
    width: 100%;
    font-weight: bold;
    padding-bottom: ${props => props.selected ? "20px" : '60px'};
    cursor: ${props => props.selected ? "" : 'pointer'};
    border-color:#FFF;
    background-color: ${props => props.selected ? '#FFF' : '#e7e3df'};
    -webkit-tap-highlight-color: transparent;

    @media (hover: hover) {
        &:hover {
            background-color:#EEE !important;
        }
    }
`;

export const ListOption = styled.li<{ selected?: boolean }>`

`;

export const ListItemImage = styled.img`
    width: 64px;
    height: 64px;
    object-fit: contain;
    margin-bottom: 20px;
`

export const RadioButtonLabel = styled.label`
    position: absolute;
    height: 24px;
    width: 15px;
    height: 15px;
    content: '';
    border: 1px solid black;
`;

export const RadioItem = styled.div`
    margin-top: 7px;
    display: flex;
    align-items: center;
    position: relative;
    font-size: 14px;
    font-weight: 400;
    border-bottom: 2px solid #e7e3df;
    width: 100%;
`;

export const RadioButton = styled.input<{ label?: string }>`
    opacity: 0;
    z-index: 1;
    cursor: pointer;
    height: 40px;
    margin-right: 10px;

    ~ ${RadioButtonLabel}::after {
        content: "";
        display: block;

        width: 14px;
        height: 14px;
        position: absolute;
        top: 4px;
        bottom: 0;
    }

    &:hover ~ ${RadioButtonLabel} {
        &::after {
            content: 'x';
            font-size: 17px;

            line-height: 3px;
            text-align: center;
        }
    }

    &:checked + ${RadioButtonLabel} {
        &::after {
            content: 'x';
            font-size: 17px;
            line-height: 3px;
            text-align: center;
        }
    }
`;

export const Icon = styled.div<{ hoverable?: boolean }>`
    width: 32px;
    height: 32px;
    cursor: pointer;
  
    ${props => props.hoverable && `
        @media(hover) {
            &:hover {
            opacity: 0.5
            }
        }
    `}

  svg {
    fill: currentColor;
    width: 100%;
  }
`

export const ZoomInIcon = styled(Icon)`
    margin-bottom: 5px;
    svg {
        fill: black;
    }
`
export const ZoomOutIcon = styled(Icon)`    
    svg {
        fill: black;
    }
`
